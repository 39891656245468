import React from "react";
import { connect } from "react-redux";

class HeroSectionTermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="hero-section ptb-100 background-img"
          style={{
            // backgroundImage: "url('img/hero-bg-1.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12 col-lg-12">
                <div className="page-header-content text-white text-center pt-sm-12 pt-md-12 pt-lg-0">
                  <h1 className="text-white mb-0">Política de privacidade</h1>
                  <div className="custom-breadcrumb">
                    <ol className="breadcrumb d-inline-block bg-transparent list-inline py-0">
                      <li className="list-inline-item breadcrumb-item">
                      Nós da Ockpay, buscamos proporcionar a você, nosso cliente, a melhor experiência em pagamentos por meio digital com cartão de crédito de maneira prática e totalmente segura e expondo a menor quantidade de dados pessoais possíveis de nossos usuários. Dessa forma, para que a nossa missão seja alcançada, é imprescindível coletar alguns de seus dados pessoais inseridos na plataforma, como o seu nome, número de cartão de crédito e as suas preferências de navegação. Esta política de privacidade lhe servirá como um guia informativo, sobre como coletaremos, armazenaremos, trataremos e não compartilharemos esses dados pessoais e, ao final de sua leitura, caso se convença e concorde com os termos aqui delineados, você poderá, de maneira livre, consciente e expressa, aceita-los para que possa usufruir do melhor que podemos lhe oferecer. ​


                      </li>
                      {/* <li className="list-inline-item breadcrumb-item">
                        <a href="/#">Pages</a>
                      </li>
                      <li className="list-inline-item breadcrumb-item active">
                        FAQ
                      </li> */}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state,
}))(HeroSectionTermsAndConditions);
