import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSectionPoliticaDePrivacidade from "../components/HeroSection/HeroSectionPoliticaDePrivacidade";
import PoliticaDePrivacidade from "../components/PoliticaDePrivacidade/PoliticaDePrivacidade";
import FooterSection from "../components/Footer";

class PoliticaPrivacidade extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main">
          <HeroSectionPoliticaDePrivacidade />
          <PoliticaDePrivacidade />
        </div>
        <FooterSection noSubscription={true} />
      </React.Fragment>
    );
  }
}

export default PoliticaPrivacidade;
