import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSectionTermsAndConditions from "../components/HeroSection/HeroSectionTermsAndConditions";
import TermsAndConditionsComponent from "../components/TermsAndConditions/TermsAndConditions";
import FooterSection from "../components/Footer";

class TermsAndConditions extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main">
          <HeroSectionTermsAndConditions />
          <TermsAndConditionsComponent />
        </div>
        <FooterSection noSubscription={true} />
      </React.Fragment>
    );
  }
}

export default TermsAndConditions;
