import React from "react";
import _data from "../../data";

export default class Screenshots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      price: _data.price
    });
  }

  render() {
    return (
      <React.Fragment>
        <section
          id="screenshots"
          className="screenshots-section ptb-100 gray-light-bg"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center">
                  <h2> Para qualquer tipo de negócio <br />
                  <span>Simples e Completo</span>
                  </h2>
                  <p className="lead">
                    Uma harmonia entre funcionalidade, design e interatividade. Tudo pensado na aproximação de quem vende e quem compra.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="screen-slider-content mt-5">
              {/*<div className="screenshot-frame"></div>*/}
              <div className="screen-carousel owl-carousel owl-theme dot-indicator">
                <img src="img/app_home.png" className="img-fluid" alt="screenshots" />
                <img src="img/Cesta.png" className="img-fluid" alt="screenshots" />
                <img src="img/Pagamento.png" className="img-fluid" alt="screenshots" />
                <img src="img/Pedidos.png" className="img-fluid" alt="screenshots" />
                <img src="img/Restaurantes.png" className="img-fluid" alt="screenshots" />
                <img src="img/Hey_burger.png" className="img-fluid" alt="screenshots" />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
