import React from "react";

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className="ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="single-faq">
                  <h4>GLOSSÁRIO:</h4>
                  <p>
                    Usuário: Pessoa física ou jurídica que utiliza o sistema
                    OCKPAY para a realização de compras, diretamente na área
                    dedicada a cada estabelecimento. Estabelecimento: Pessoa
                    jurídica que anuncia seus produtos na plataforma OCKPAY.
                    Software: sistema computadorizado com todas as
                    funcionalidades e ferramentas oferecidas pela OCKPAY.
                    Hardware: equipamento o qual comportará o uso do software.
                    Exemplos: computadores, “smartphone”, “tablets” e outros.
                    NFC: tecnologia que possibilita o pagamento por aproximação
                    de dispositivo, na relação entre CONTRATADA e CONTRATANTE a
                    tecnologia estará empregada em etiqueta que ficará visível
                    nas mesas do estabelecimento; Nuvem: Ambiente virtual onde
                    são processadas e armazenadas informações; QR Code: código
                    de barras bidimensional, que é de fácil e rápida leitura por
                    meio de dispositivos.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="single-faq">
                  <br />
                  <h5>1. A OCKPAY </h5>
                  <p>
                    <strong>1.1. A</strong> OCKPAY, cuja a razão social é PENSE
                    APP DESENVOLVIMENTO DE APLICATIVOS LTDA, pessoa jurídica de
                    direito privado, inscrita no CNPJ sob o nº
                    25.450.851/0001-67, com sede na Rua Henrique Miers, 226 -
                    Apt 203 Box 3 -Costa e Silva na cidade de Joinville/SC – CEP
                    89.218-580, é uma empresa que propicia aos seus clientes,
                    uma plataforma de pagamento online, por meio de um sistema
                    rápido, moderno e dinâmico.
                  </p>
                  <p>
                    <strong>1.2.</strong> A OCKPAY não é uma garantidora dos
                    negócios avençados entre as partes, limitando sua atuação na
                    aproximação do usuário com o estabelecimento.
                  </p>
                  <p>
                    <strong>1.3.</strong> A OCKPAY oferta uma interface na qual
                    o estabelecimento anuncia suas mercadorias e o usuário, por
                    seu turno, tem acesso a um portfólio digital, no qual pode
                    adquirir tais mercadorias, diretamente nesta interface, sem
                    atuação humana, mediante a utilização das formas e das
                    condições de pagamento ofertadas pelo estabelecimento.
                  </p>
                  <p>
                    <strong>1.4.</strong> A OCKPAY não possui qualquer tipo de
                    gerência acerca dos métodos e condições de pagamentos
                    ofertados pelo estabelecimento, sendo que sua atuação se
                    limita somente na viabilização da negociação entre as
                    partes, por intermédio da plataforma já mencionada.
                  </p>
                  <p>
                    <strong>1.5.</strong> A plataforma acima mencionada será
                    operada de maneira “on-line” por navegador web, por meio de
                    dispositivos móveis que contenham os sistemas operacionais
                    Android, IOS, MAC ou Windows.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="single-faq">
                  <br />
                  <h5>2. DOS SERVIÇOS DISPONIBILIZADOS</h5>

                  <p>
                    <strong>2.1.</strong> A OCKPAY oferta a você, usuário, a
                    possibilidade de acessar os produtos de uma gama de
                    estabelecimentos sem sair da sua casa ou ainda, facilita
                    seus pedidos, caso queira se deslocar até ao
                    estabelecimento.
                  </p>
                  <p>
                    <strong>2.2.</strong> O usuário pode acessar via link de
                    internet, QR Code ou via NFC o portfólio de produtos do
                    estabelecimento e, de maneira simples, ágil e segura,
                    efetuar sua compra.
                  </p>
                  <p>
                    <strong>2.3.</strong> A OCKPAY oferta um portal de
                    pagamentos online, no qual, mediante a inserção dos dados de
                    seu cartão de crédito, você pode pagar a compra efetuada,
                    com segurança e rapidez, sendo que o vendedor é avisado em
                    tempo real acerca do pagamento.
                  </p>
                  <p>
                    <strong>2.4.</strong> A OCKPAY não salva, arquiva ou
                    disponibiliza qualquer tipo de dados pessoal do usuário,
                    garantindo a lisura e segurança das operações.
                  </p>
                  <p>
                    <strong>2.5.</strong> A escolha dos meios e da forma de
                    pagamento cabe tão e somente ao estabelecimento comercial,
                    sendo que a OCKPAY disponibiliza uma gama de meios e formas
                    de pagamento, porém não cabe a ela a decisão final sobre o
                    que será disponibilizado ao usuário, podendo variar de
                    acordo com cada estabelecimento.
                  </p>
                  <p>
                    <strong>2.6.</strong> Cumpre informar que o usuário nada
                    pagará para se utilizar da plataforma, cabendo tão e somente
                    o estabelecimento o pagamento das taxas do serviço ora
                    disponibilizado.
                  </p>
                  <p>
                    <strong>2.7.</strong> A OCKPAY disponibiliza um canal de
                    atendimento ao usuário por meio do web site
                    www.ockpay.com.br, e, também, pelo gestor Ockpay no botão
                    “Ajuda”. Os canais funcionam de segunda à sexta-feira das
                    08:00 às 18:00.
                  </p>
                  <p>
                    <strong>2.8.</strong> A OCKPAY disponibiliza uma interface
                    onde o usuário poderá acompanhar seus pedidos em tempo real,
                    sendo que a obrigação por disponibilizar estas informações é
                    de caráter exclusivo do estabelecimento.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="single-faq">
                  <br />
                  <h5>3. DAS OBRIGAÇÕES DOS USUÁRIOS</h5>

                  <p>
                    <strong>3.1.</strong> O usuário se compromete a respeitar a
                    ética, as leis e o disposto neste termo e na política de
                    privacidade.
                  </p>

                  <p>
                    <strong>3.2.</strong> O usuário se compromete a utilizar o
                    sistema de maneira idônea e dentro dos limites legais, se
                    responsabilizando por qualquer tipo de dano que possa vir a
                    causar no estabelecimento comercial.
                  </p>

                  <p>
                    <strong>3.3.</strong> Deve o usuário, no ato da compra, se
                    utilizar de cartão de crédito ou outro meio de pagamento de
                    sua própria titularidade, respondendo este cível e
                    criminalmente, pela utilização de cartões ou métodos de
                    pagamento cuja a titularidade seja de terceiro, com ou sem
                    autorização deste.
                  </p>

                  <p>
                    <strong>3.4.</strong> É responsabilidade do usuário o
                    contato com o estabelecimento para agendar a retirada do
                    produto adquirido.
                  </p>

                  <p>
                    <strong>3.5.</strong> O usuário deve se atentar a todos os
                    detalhes da compra, principalmente quando a mercadoria
                    adquirida tenha caráter perecível ou alimentício, cabendo
                    somente ao mesmo informar detalhes peculiares e
                    particularidades, para que a venda se dê da melhor maneira
                    possível e o produto atinja as expectativas.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="single-faq">
                  <br />
                  <h5>4. DAS LIMITAÇÕES DE RESPONSABILIDADE DA OCKPAY</h5>

                  <p>
                    <strong>4.1.</strong> A OCKPAY é uma mera plataforma de
                    aproximação entre o estabelecimento e o usuário e que
                    possibilita um portal de pagamentos para a efetivação de
                    vendas. Em assim sendo, não cabe a OCKPAY a responsabilidade
                    da entrega do produto ou ainda quanto a qualidade,
                    quantidade, etc., da mercadoria adquirida.
                  </p>
                  <p>
                    <strong>4.2.</strong> A OCKPAY não participa, altera,
                    elabora edita ou monitora qualquer conteúdo, sendo que as
                    mercadorias anunciadas são de exclusiva responsabilidade do
                    estabelecimento.
                  </p>
                  <p>
                    <strong>4.3.</strong> Fica desde já esclarecido que a OCKPAY
                    não responde, de forma alguma, acerca de entregas,
                    qualitativo ou quantitativo de mercadorias adquiridas, sendo
                    que a OCKPAY ofertará ao usuário todos os dados necessários
                    para localizar o fornecedor de sua mercadoria, para que este
                    sim possa responder por vícios e/ou defeitos.
                  </p>
                  <p>
                    <strong>4.4.</strong> A OCKPAY não poderá ser
                    responsabilizada, direta ou indiretamente, por quaisquer
                    custos, gastos, danos ou perdas sofridos pelo usuário em
                    função de culpa exclusiva do estabelecimento, seja à que
                    título for.
                  </p>
                  <p>
                    <strong>4.5.</strong> A responsabilidade da OCKPAY se limita
                    a disponibilizar o portfólio de produtos e ofertar um portal
                    de pagamentos, repassando o dinheiro recebido do usuário ao
                    estabelecimento. Fica desde já entendido que a
                    responsabilidade da OCKPAY se funda estritamente nesta
                    operação e nada mais, o que desde já resta aceito pelo
                    usuário.
                  </p>
                  <p>
                    <strong>4.6.</strong> Qualquer oferta, condição ou
                    mercadoria oferecida é de exclusiva responsabilidade do
                    estabelecimento, o que desde já resta aceito e entendido
                    pelo usuário.
                  </p>
                  <p>
                    <strong>4.7.</strong> A OCKPAY não possui com o usuário
                    qualquer tipo de ajuste que se refira a garantia de tempo no
                    ar da plataforma, não podendo ser responsabilizada neste
                    sentido.
                  </p>
                  <p>
                    <strong>4.8.</strong> A OCKPAY se reserva ao direito de, a
                    qualquer momento, retirar sua plataforma do ar pelo tempo
                    que lhe convir, garantindo, tão e somente, a efetivação das
                    transações até então efetuadas.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="single-faq">
                  <br />
                  <h5>5. DA VIGÊNCIA DO PRESENTE TERMO</h5>

                  <p>
                    <strong>5.1.</strong> O presente termo vigorará por tempo
                    indeterminado, bastando que o usuário deixe de se utilizar
                    da plataforma ofertada pela OCKPAY, por mais de 60 dias,
                    para que este seja rescindindo sem qualquer tipo de ônus.
                  </p>
                  <p>
                    <strong>5.2.</strong> Caso o presente termo seja rescindido
                    por inatividade, quando de nova utilização no software,
                    deverá o usuário novamente aceita-lo para poder se utilizar
                    dos serviços ofertados.
                  </p>
                  <p>
                    <strong>5.3.</strong> Caso o usuário queira se desvincular
                    de maneira imediata da plataforma, deverá encaminhar um
                    e-mail para suporte@ockpay.com.br e, sem qualquer custo,
                    será totalmente desvinculado em até 48h.
                  </p>
                  <p>
                    <strong>5.4.</strong> Considerando ser um serviço gratuito
                    ao usuário, a plataforma e os serviços poderão ser
                    cancelados a qualquer tempo pela OCKPAY, sem qualquer tipo
                    de ônus.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div cla0ssName="single-faq">
                  <br />
                  <h4>6. DO ARMAZENAMENTO DOS PEDIDOS</h4>
                  <p>
                    6.1. A OCKPAY armazenará as informações dos pedidos
                    efetivados pelo usuário por tempo indeterminado, pelo prazo
                    mínimo de 2 (dois) anos.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="single-faq">
                  <h4>7. DAS DISPOSIÇÕES GERAIS</h4>
                  <p>
                    7.1. Considera-se assinado e, consequentemente, aceito o
                    presente termo pelo usuário, quando este clicar no botão “li
                    e aceito”, sendo este aceite de caráter irrevogável e
                    irretratável. O usuário declara e garante expressamente que
                    possui capacidade jurídica para celebrar o presente
                    documento.
                  </p>
                  <p>
                    7.2. A forma e o formato da disponibilização da plataforma
                    poderá, à qualquer tempo, ser alterada pela OCKPAY, sem
                    qualquer tipo de ônus, desde que não contrarie ou revogue
                    qualquer tipo de disposição contida neste contrato.
                  </p>
                  <p>
                    7.3. Este termo permanecerá vigente mesmo que uma parte seja
                    julgada inaplicável ou sem efeito, sendo que o restante se
                    manterá aplicável.
                  </p>

                  <p>
                    7.4. O presente termo também poderá ser alterado, a qualquer
                    tempo, pela OCKPAY, sendo que neste caso, o usuário será
                    avisado e deverá, caso concorde, exprimir seu aceite
                    novamente nas novas condições.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="single-faq">
                  <h4>CLÁUSULA DÉCIMA SEGUNDA – DO FORO </h4>
                  <p>
                    12.1. Fica eleito o foro da Comarca de Americana/SP, com a
                    renúncia expressa de qualquer outro, por mais especial ou
                    privilegiado que seja ou possa vir a ser, como o único
                    competente para conhecer, apreciar e dirimir toda e qualquer
                    que diga respeito à existência, cumprimento e validade do
                    mesmo contrato. E por estarem as partes de pleno acordo em
                    tudo quanto se encontra disposto neste instrumento
                    particular, o usuário apresente seu aceite de forma digital,
                    se submetendo a todas as cláusulas e condições aqui
                    dispostas.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="single-faq">
                  <p>Americana, 14 de maio de 2020.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default TermsAndConditions;
