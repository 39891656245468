import React from "react";
import { connect } from "react-redux";

class HeroSectionTermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="hero-section ptb-100 background-img"
          style={{
            // backgroundImage: "url('img/hero-bg-1.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12 col-lg-12">
                <div className="page-header-content text-white text-center pt-sm-12 pt-md-12 pt-lg-0">
                  <h1 className="text-white mb-0">TERMOS E CONDIÇÕES GERAIS DE USO</h1>
                  <div className="custom-breadcrumb">
                    <ol className="breadcrumb d-inline-block bg-transparent list-inline py-0">
                      <li className="list-inline-item breadcrumb-item">
                      Olá usuário! Bem vindo a Ockpay. Sua próxima plataforma de pagamentos. 
  O objetivo deste documento é lhe ofertar informações claras, diretas e objetivas acerca de nossos serviços, que somado a nossa política de privacidade, garantirá a você o uso pleno e seguro do nosso sistema de pagamento. 
  Para melhor entendimento dos termos a serem utilizados neste documento, segue abaixo glossário:
                      </li>
                      {/* <li className="list-inline-item breadcrumb-item">
                        <a href="/#">Pages</a>
                      </li>
                      <li className="list-inline-item breadcrumb-item active">
                        FAQ
                      </li> */}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state,
}))(HeroSectionTermsAndConditions);
