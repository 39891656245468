import React, { Component } from "react";
import { connect } from "react-redux";
import { submitContact } from "../../actions/index";
import _data from "../../data";
import HubspotForm from "react-hubspot-form";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      disableContactBtn: false,
      contactBtnText: "Enviar mensagem",
      contact: {}
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * When we click on Send button, changeBtnText function will help to change text
   * @param contactBtnText
   */
  changeBtnText = contactBtnText => {
    this.setState({ contactBtnText });
  };

  /**
   * Get all form data and set to the state
   * @param contactBtnText
   */
  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  /**
   * Submit the form and dispatch to the store
   * @param contactBtnText
   */
  handleSubmit(event) {
    event.preventDefault();

    // disable the button
    this.setState({ disableContactBtn: true });

    // get action
    const contactAction = submitContact(this.state);

    // Dispatch the contact from data
    this.props.dispatch(contactAction);

    // added delay to change button text to previous
    setTimeout(
      function() {
        // enable the button
        this.setState({ disableContactBtn: false });

        // change to button name
        // this.changeBtnText("Send Message");

        // get action again to update state
        const contactAction = submitContact(this.state);

        // Dispatch the contact from data
        this.props.dispatch(contactAction);

        // clear form data
        this.setState({
          name: "",
          email: "",
          phone: "",
          company: "",
          message: ""
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      contact: _data.contact
    });
  }

  render() {
    return (
      <React.Fragment>
        <section id="contact" className={"contact-us ptb-100 " + (this.props.bgColor && this.props.bgColor === 'white' ? '' : 'gray-light-bg')}>
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="section-heading">
                  <h3>{this.state.contact.title}</h3>
                  <p>{this.state.contact.description}</p>
                </div>
                <div className="footer-address">
                  <h6>
                    <strong>{this.state.contact.office}</strong>
                  </h6>
                  <p>{this.state.contact.address}</p>
                  <ul>
                    <li>
                      {/* <span>Phone: {this.state.contact.phone}</span> */}
                    </li>
                    <li>
                      <span>
                        {/* Email :{" "} */}
                        <a href="mailto:hello@yourdomain.com">
                          {this.state.contact.email}
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-7">
              <div class="testimonial-quote-wrap my-lg-3 my-md-3 rounded white-bg shadow-sm p-5">
                <HubspotForm
                    portalId="8249903"
                    formId="029dafaa-d35a-49ac-84b9-4ea9945b2f54"
                    onSubmit={(data) => console.log(data)}
                    onReady={(form) => console.log(form)}
                    loading={<div>Loading...</div>}
                  />
                  </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(Contact);
