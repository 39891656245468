import React from "react";
import { Link } from "react-router-dom";

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="download-section pt-100 background-img"
          style={{
            // backgroundImage: "url('img/app-hero-bg.jpg')",
            backgroundImage: "url('https://i.imgur.com/ilbLTUj.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover"
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6">
                <div
                  id="accordion-1"
                  className="accordion accordion-faq pb-100"
                >
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-1"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-1"
                      aria-expanded="false"
                      aria-controls="collapse-1-1"
                    >
                      <h6 className="mb-0">
                        <span className="ti-receipt mr-3"></span> O que é preciso para vender na Ockpay?

                      </h6>
                    </div>
                    <div
                      id="collapse-1-1"
                      className="collapse show"
                      aria-labelledby="heading-1-1"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                        Apenas ter força de vontade, pois qualquer pessoa pode vender na Ockpay. Com CPF ou CNPJ basta se cadastrar no plano que mais interessar e começar a faturar.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-2"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-2"
                      aria-expanded="false"
                      aria-controls="collapse-1-2"
                    >
                      <h6 className="mb-0">
                        <span className="ti-gallery mr-3"></span> Posso vender na Ockpay?
                      </h6>
                    </div>
                    <div
                      id="collapse-1-2"
                      className="collapse"
                      aria-labelledby="heading-1-2"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                         A Ockpay foi feita para bares, restaurantes, farmácias, lojas de construção, lojas de roupas, livrarias, pet shops e muito mais.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-3"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-3"
                      aria-expanded="false"
                      aria-controls="collapse-1-3"
                    >
                      <h6 className="mb-0">
                        <span className="ti-wallet mr-3"></span> Quanto tempo leva para eu começar a vender?

                      </h6>
                    </div>
                    <div
                      id="collapse-1-3"
                      className="collapse"
                      aria-labelledby="heading-1-3"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                         Você precisa de 5min para se cadastrar com os dados fundamentais e depois alguns poucos minutos para montar seu menu. 
                         Fizemos tudo para que em 10min você já comece a mostrar seu talento online!

                        </p>
                      </div>
                    </div>
                  </div>
                    <Link to={`/faq`} className="button btn solid-btn">
                      Ver mais dúvidas
                    </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="download-img d-flex align-items-start">
                  <img
                    // src="img/image-13.png"
                    src="https://i.imgur.com/jsWKVJE.png"
                    alt="download"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Faq;
