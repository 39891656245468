import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useForm } from 'react-hubspot'
import styled, { css } from "styled-components";

const SuccessMessage = styled.h3`
  color: #82ec82;
  margin-top: 10px;
  margin-left: 12px;
`
const ErrorMessage = styled.h6`
  color: #ff8686;
  margin-top: 10px;
  margin-left: 12px;
`

const SubmitButton = styled.input`
  ${({ disabled }) => disabled && css`
    background: #e6e6e6;
    border: 2px solid #c7ced0;
    color: #6c757d;
  `}
`

const Subscribe = () => {
  const [submitedTrue, setSubmitedTrue] = useState(false);
  const [email, setEmail] = useState(''); 
  const [disabledEmail, setDisabledEmail] = useState(true);
  const { data, isLoading, isError, handleSubmit } = useForm({
    portalId: '8249903',
    formId: '68b141bc-cc50-4aac-9746-aeac92182268'
  })
  
  useEffect(() => {
    const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    setDisabledEmail(reg.test(email))
    console.log('reg.test(email)', reg.test(email))
  }, [email]);

  useEffect(() => {
    setSubmitedTrue(!isError && !isLoading && data)
  }, [data, isError, isLoading]);

  return (
    <>
      <div className="shape-img subscribe-wrap">
        <img
          // src="img/footer-top-shape.png"
          src="https://i.imgur.com/NUE4d2q.png"
          alt="footer shape"
          className="img-fluid"
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              {/* <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/shell.js"></script>
              <script>
                hbspt.forms.create({
                portalId: "8249903",
                formId: "68b141bc-cc50-4aac-9746-aeac92182268"
              });
              </script> */}
              <form
                className="subscribe-form subscribe-form-footer d-none d-md-block d-lg-block"
                onSubmit={handleSubmit}
              >
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    className="form-control input"
                    id="email-footer"
                    name="email"
                    placeholder="meuemail@email.com"
                    onChange={event => setEmail(event.target.value)}
                    />
                  <SubmitButton
                    type="submit"
                    className="button btn solid-btn"
                    id="submit-footer"
                    disabled={!disabledEmail}
                    />
                </div>
                {isError && <ErrorMessage>Oopss, ocorreu um erro, tente novamente</ErrorMessage>}
                {submitedTrue && <SuccessMessage>Email cadastrado!</SuccessMessage>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect((state) => ({
  state,
}))(Subscribe);
