import React from "react";

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className="ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="single-faq">
                  <h4>1. Quais dados serão coletados e de que forma</h4>
                  <p>
                    À medida em que você preenche os formulários em branco na
                    plataforma da Ockpay, nós coletaremos os seguintes dados:
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="single-faq">
                  <br />
                  <h5>Dados de identificação pessoal:</h5>
                  nós perguntaremos o seu nome completo, CPF e e-mail.
                </div>
              </div>
              <div className="col-md-12">
                <div className="single-faq">
                  <br />
                  <h5>Dados bancários – cartão de crédito:</h5>
                  para que o pagamento por meio de nossa plataforma possa ser
                  efetivado, nós perguntaremos o número completo do seu cartão
                  de crédito, o nome do titular conforme impresso no cartão, a
                  data de vencimento do cartão e o seu código de segurança.
                  Jamais pediremos a senha do seu cartão de crédito e caso isto
                  ocorra, provavelmente você está sendo alvo de algum tipo de
                  ataque cibernético e a operação deve ser cancelada
                  imediatamente.
                </div>
              </div>
              <div className="col-md-12">
                <div className="single-faq">
                  <br />
                  <h5>Informações técnicas:</h5>
                  nós coletaremos as informações técnicas inerentes ao
                  dispositivo utilizado para acesso à interface da Ockpay,
                  dentre essas informações estão o endereço de IP que conecta o
                  dispositivo à Internet e qual o provedor de serviços desta, o
                  tipo e versão do navegador web, a preferência de idioma e o
                  tipo de sistema operacional. A coleta dessas informações se
                  dará de maneira automática no ato de aceite desta política de
                  privacidade.
                </div>
              </div>
              <div className="col-md-12">
                <div className="single-faq">
                  <br />
                  <h5>Cookies de navegação:</h5>
                  com o intuito de otimizar ao máximo a navegação em nossa
                  plataforma, nós coletaremos de maneira automática os seus
                  padrões de navegação (cookies), ou seja, o histórico de suas
                  preferências. Essas informações serão salvas e assim, sempre
                  que estiver navegando na plataforma, os cookies facilitarão o
                  transporte de suas informações e preferências, dando rápido
                  carregamento ao portfólio do estabelecimento.
                </div>
              </div>
              <div className="col-md-12">
                <div className="single-faq">
                  <br />
                  <h5>Dados de localização:</h5>
                  no momento em que você aceitar essa política de privacidade e
                  somente enquanto o domínio ockpay.com.br ou similar estiver
                  ativo em seu dispositivo, nós captaremos a sua localização com
                  o intuito de, em primeiro lugar, garantir maior segurança em
                  suas transações. Podemos também, utilizar seus dados de
                  localização para lhe indicar estabelecimentos próximos que
                  aceitem o nosso sistema, com objetivo único de simplificar
                  ainda mais a sua vida.
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div cla0ssName="single-faq">
                  <br />
                  <h4>2. Onde e como os seus dados serão armazenados</h4>
                  <p>
                    A Ockpay armazenará os dados fornecidos em um sistema de
                    nuvem, isto é, em um sistema online de armazenamento
                    totalmente confiável, que também fica subordinado a essa
                    política de privacidade. Após a transação, a maioria dos
                    dados são descartados, em especial os dados de cartões de
                    crédito, restando tão e somente cookies que agilizarão
                    acesso posterior. Salienta-se que, caso utilize GPay,
                    ApplePay e SamsungPay, seus dados poderão, a seu critério e
                    sem qualquer influência da Ockpay, serem salvos nestas
                    carteiras digitais. Sendo assim, a Ockpay se isenta de
                    qualquer responsabilidade acerca de vazamento de dados
                    bancários, cuja origem seja uma dessas carteiras.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="single-faq">
                  <h4>3. Porque os seus dados são importantes para nós</h4>
                  <p>
                    Nossa missão é fornecer o serviço de pagamento online,
                    pautado na simplicidade e praticidade. Portanto, a Ockpay
                    utilizará de seus dados e cookies para o aperfeiçoamento de
                    nossos serviços, revestindo a sua experiência na mais
                    personalizada e ágil possível. Ao coletarmos o seu nome, por
                    exemplo, poderemos dar as boas-vindas ao acessar a
                    plataforma, assim como o estabelecimento poderá se valer
                    dessa informação para tratar-lhe diretamente e de maneira
                    pessoal. Além disso, as informações, dados e cookies
                    coletados, ainda aperfeiçoarão a sua experiência dando
                    rapidez ao acesso e carregamento dos ícones, imagens e
                    demais elementos da interface do estabelecimento.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="single-faq">
                  <h4>4. Como protegeremos os seus dados</h4>
                  <p>
                    Ao inserir os seus dados pessoais, bem como ao autorizar a
                    utilização dos cookies de navegação, a Ockpay se utilizará
                    dos mesmos apenas para cumprir o previsto no tópico 3.
                    Portanto, neste ponto, importante destacar que os seus dados
                    serão protegidos em nuvem e, desse modo, não os
                    compartilharemos com terceiros para a finalidade de promover
                    publicidade e propaganda dirigidas ao seu perfil de
                    consumidor de produtos. O mesmo tratamento será dado aos
                    seus dados bancários e, além disso, ao informar o seu
                    endereço de e-mail, a Ockpay poderá conceder maior proteção
                    à sua compra, pois não só enviaremos os dados desta para o
                    e-mail, como principalmente poderemos verificar a
                    legitimidade da compra através de sistema antifraude,
                    buscando evitar qualquer modalidade de uso indevido do
                    cartão, bem como contra acesso não autorizado, divulgação e
                    alteração dos seus dados. Para resguardar seus dados de
                    ataques cibernéticos que possam causar o vazamento indevido
                    de informações, utilizamos os métodos mais modernos de
                    criptografia e monitoramos constantemente os nossos
                    serviços, porém, considerando que existem causas
                    supervenientes que podem acarretar perda ou vazamento de
                    informações, a Ockpay, desde que comprovado que tomou todas
                    as medidas de proteção, não poderá ser responsabilizada.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="single-faq">
                  <h4>5. Destinação de nossos serviços</h4>
                  <p>
                    Nossos serviços, por envolver, exclusivamente, transações
                    comerciais mediante pagamento via cartão de crédito, é
                    direcionado para pessoas maiores de 18 (dezoito) anos,
                    titulares de cartão de crédito, ou menores de idade, desde
                    que possuam cartões dependentes. Fica vedado o uso de
                    cartões de terceiros para a utilização de nossos serviços,
                    sendo que, caso isto ocorra, o usuário deverá arcar com os
                    ônus cíveis e criminais de tal conduta.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="single-faq">
                  <h4>6. Exceções para o compartilhamento de informações</h4>
                  <p>
                    Muito embora nós armazenemos a mínima quantidade de dados
                    possíveis, em alguns casos, estas informações poderão ser
                    compartilhadas, por se tratar de uma exceção legal ou
                    especial. São consideradas exceções legais para o
                    compartilhamento de informações: requisição formal de
                    autoridade policial, judiciária ou pública e; alterações
                    legais que obriguem tal ato. Além das exceções legais acima
                    apontadas, a Ockpay poderá se utilizar de seus dados caso
                    haja a suspeita de fraudes ou ainda atitudes que possam
                    prejudicar a própria Ockpay ou a terceiros. A Ockpay também
                    poderá compartilhar suas informações com empresas ligadas ao
                    mesmo grupo ou ainda que façam parte da cadeia de
                    procedimentos necessários para a concretização das
                    negociações, porém todos os envolvidos, estarão sob a
                    responsabilidade da Ockpay e deverão respeitar a política de
                    privacidade ora convencionada.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="single-faq">
                  <h4>7. Dúvidas sobre a nossa política de privacidade</h4>
                  <p>
                    Caso tenha dúvidas acerca da nossa política de privacidade,
                    ficaremos felizes em esclarecê-las. Para tanto, basta
                    encaminhar um e-mail para privacidade@ockpay.com.br que, em
                    breve, lhe encaminharemos os esclarecimentos que se façam
                    necessários.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="single-faq">
                  <h4>8. Considerações finais </h4>
                  <p>
                    Esta Política de Privacidade foi elaborada em conformidade
                    com a Lei Federal n. 12.965 de 23 de abril de 2014 (Marco
                    Civil da Internet), com a Lei Federal n. 13.709, de 14 de
                    agosto de 2018 (Lei de Proteção de Dados Pessoais) e com o
                    Regulamento UE n. 2016/679 de 27 de abril de 2016
                    (Regulamento Geral Europeu de Proteção de Dados Pessoais -
                    RGDP). Esta Política de Privacidade poderá ser atualizada em
                    decorrência de eventual atualização normativa, razão pela
                    qual poderemos enviar-lhe um aviso via e-mail e, após,
                    inserir as alterações em nossa plataforma.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default TermsAndConditions;
