const { planMockedCallAction } = require("../components/HeroSection/planMockedText");

module.exports = {
  hero: {
    title: "Brainstorming for desired perfect Usability",
    description:
      "Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!",
    bgImage: "img/app-hero-bg.jpg"
  },
  promo: {
    title: "Why small business owners love AppCo?",
    description:
      "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
    items: [
      {
        title: "Clean Design",
        description:
          "Increase sales by showing true dynamics of your website",
        icon: "ti-vector text-white"
      },
      {
        title: "Secure Data",
        description:
          "Build your online store’s trust using Social Proof & Urgency",
        icon: "ti-lock text-white"
      },
      {
        title: "Retina Ready",
        description:
          "Realize importance of social proof in customer’s purchase decision",
        icon: "ti-eye text-white"
      }
    ]
  },
  aboutUs: {
    title: {
      bold: "Use your android or ios device",
      normal: ""
    },
    description:
      "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
    items: [
      {
        title: "Clean Design",
        description:
          "Increase sales by showing true dynamics of your website",
        icon: "ti-vector text-white"
      },
      {
        title: "Secure Data",
        description:
          "Build your online store’s trust using Social Proof & Urgency",
        icon: "ti-lock text-white"
      },
      {
        title: "Retina Ready",
        description:
          "Realize importance of social proof in customer’s purchase decision",
        icon: "ti-eye text-white"
      }
    ]
  },
  price: {
    title: "Pricing Packages",
    description:
      "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
    packages: [
     {
        price: "R$ 0",
        // promotion: false,
        license: "Grátis",
        isFeatured: false,
        imgPath: "img/basic.svg",
        callActionText: planMockedCallAction.free.text,
        callActionBulletPoints: planMockedCallAction.free.bulletPoints,
        buttonText: "Começar agora",
        buttonLink: "/cadastro/free",
        features: [
          "Sistema gestor inteligente",
          "Link personalizado",
          "Vendas pelo App Ockpay",
          "Até 25 produtos"
        ]
      },
      {
        price: "R$ 39",
        // promotion: "R$ 19",
        license: "Essencial",
        isFeatured: true,
        imgPath: "img/unlimited.svg",
        callActionText: planMockedCallAction.essencial.text,
        callActionBulletPoints: planMockedCallAction.essencial.bulletPoints,
        buttonText: "Começar agora",
        buttonLink: "/cadastro/essential",
        features: [
          "Tudo do Grátis",
          "Atendimento presencial",
          "Pagamento online",
          "Sem limite de produtos"
        ]
      },
      {
        price: "R$ 69",
        // promotion: "R$ 35",
        license: "Premium",
        isFeatured: false,
        imgPath: "img/standard.svg",
        callActionText: planMockedCallAction.premium.text,
        callActionBulletPoints: planMockedCallAction.premium.bulletPoints,   
        buttonText: "Começar agora",
        buttonLink: "/cadastro/premium",
        features: [
          "Tudo do Essencial",
          "Entrega com Motoboy",
          "Indicadores de Vendas (Em breve)",
          "Entrega para todo Brasil (Em breve)"
        ]
      }
    ]
  },
  testimonial: {
    title: "What customer say about us",
    description:
      'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    comments: [
      {
        name: "Arabella Ora",
        company: "AgencyCo",
        image: "img/client-2.jpg",
        comment:
          "Completely build backend ROI whereas cross-media metrics. Collaboratively deploy customer directed web-readiness via global testing procedures. Appropriately reinvent distributed innovation without proactive catalysts for change. Distinctively."
      },
      {
        name: "John Charles",
        company: "ThemeTags",
        image: "img/client-1.jpg",
        comment:
          "If you can design one thing you can design everything with Front. Just believe it. Collaboratively repurpose performance based e-commerce without cost. It's beautiful and the coding is done quickly and seamlessly."
      },
      {
        name: "Jeremy Jere",
        company: "WorldCheck",
        image: "img/client-3.jpg",
        comment:
          "The template is really nice and offers quite a large set of options. It's beautiful and the coding is done. If you can design one thing you can design everything with Front. Just believe it."
      },
      {
        name: "Ciaran Kierce",
        company: "AppCo",
        image: "img/client-4.jpg",
        comment:
          "I just wanted to share a quick note and let you know that you guys do a really good job. I’m glad I decided to work with you. It’s really great how easy your websites are to update and manage. I never have any problem at all."
      }
    ]
  },
  contact: {
    title: "Fale conosco!",
    description:
      "Ficou com alguma dúvida ou deseja falar com nossa equipe?",
    // office: "Head Office",
    // address: "121 King St, Melbourne VIC 3000, Australia",
    // phone: "+61 2 8376 6284",
    // email: "hello@yourdomain.com"
  },
  trustedCompany: [
    "img/client-1-color.png",
    "img/client-6-color.png",
    "img/client-3-color.png",
    "img/client-4-color.png",
    "img/client-5-color.png"
  ],
  trustedCompanyGray: [
    "img/client-1-gray.png",
    "img/client-2-gray.png",
    "img/client-3-gray.png",
    "img/client-4-gray.png",
    "img/client-5-gray.png"
  ],
  trustedCompanyAboutUs: {
    title: "Quem já está bombando",
    description: "Em poucos minutos eles se conectaram e começaram a vender com ockpay e transformaram os seus atendimentos online e local.",
    companies: [
      "https://static.wixstatic.com/media/e8c11f_423527e617c94b0985e6fa388b6bb1ab~mv2.png/v1/fill/w_104,h_104,al_c,q_85,usm_0.66_1.00_0.01/e8c11f_423527e617c94b0985e6fa388b6bb1ab~mv2.webp",
      "https://static.wixstatic.com/media/e8c11f_c1928873c094460cbce3dff1da3720d3~mv2.png/v1/fill/w_104,h_104,al_c,q_85,usm_0.66_1.00_0.01/e8c11f_c1928873c094460cbce3dff1da3720d3~mv2.webp",
      "https://static.wixstatic.com/media/e8c11f_7e4abf37dd724cdb819acc9ef20a6b9d~mv2.png/v1/fill/w_106,h_104,al_c,q_85,usm_0.66_1.00_0.01/e8c11f_7e4abf37dd724cdb819acc9ef20a6b9d~mv2.webp",
      "https://static.wixstatic.com/media/e8c11f_40d85352cdb04b139821bb78f751c582~mv2.png/v1/fill/w_104,h_104,al_c,q_85,usm_0.66_1.00_0.01/e8c11f_40d85352cdb04b139821bb78f751c582~mv2.webp",
      "https://static.wixstatic.com/media/e8c11f_8398e23c38ad457db514f0e7f64457fc~mv2.png/v1/fill/w_104,h_104,al_c,q_85,usm_0.66_1.00_0.01/e8c11f_8398e23c38ad457db514f0e7f64457fc~mv2.webp",
      "https://static.wixstatic.com/media/e8c11f_da3102b731564cc5b0f99db4cef18b62~mv2.png/v1/fill/w_104,h_104,al_c,q_85,usm_0.66_1.00_0.01/e8c11f_da3102b731564cc5b0f99db4cef18b62~mv2.webp",
    ]
  },
  teamMember: {
    members: [
      {
        name: "Edna Mason",
        designation: "Senior Designer",
        image: "img/team-4.jpg"
      },
      {
        name: "Arabella Ora",
        designation: "Senior Designer",
        image: "img/team-1.jpg"
      },
      {
        name: "John Charles",
        designation: "Senior Designer",
        image: "img/team-2.jpg"
      },
      {
        name: "Jeremy Jere",
        designation: "Senior Designer",
        image: "img/team-3.jpg"
      },
    ]
  },
  video: {
    link: "https://www.youtube.com/watch?v=9No-FiEInLA"
  }
};
