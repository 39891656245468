import React from "react";

class AboutUsPresencial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="about" className="about-us ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
              <div className="about-content-right">
                  <img
                    src="img/qr_code.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
                
              </div>
              <div className="col-md-7">
              <span className="badge badge-primary badge-pill">
                Pedidos no local 
                </span>
                <h2 className="mt-4">Surpreenda seus clientes com pedidos no local</h2>
                <p className="mb-4 lh-190">
                Pensado para reduzir custos de operação e simplificar a experiência de compra dos consumidores, nosso sistema de pedidos no local (na mesa para bares e restaurantes) elimina erros de pedidos e acaba com as filas na hora de pagar.
                </p>
                <ul className="list-unstyled">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          QR Code na mesa para acesso ao cardápio digital
                          </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Pagamento no celular do consumidor sem maquininha 
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Pedido vai direto para a cozinha sem a necessidade de atendimento 
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          É só colocar o display na mesa e o consumidor realiza seu próprio atendimento
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Nenhum investimento em equipamentos
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUsPresencial;
