import React from "react";
import { connect } from "react-redux";

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="hero-section pt-100 background-img"
          style={{
            // backgroundImage: "url('img/hero-bg-1.jpg')",
            backgroundImage: "url('https://i.imgur.com/0M88Zyn.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6">
                <div className="hero-content-left text-white mt-5">
                  <h1 className="text-white">
                    Tudo em um só lugar!
                  </h1>
                  <p className="lead">
                    50% OFF nos três primeiros meses em nosso plano essencial.
                  </p>

                  <a href="#precos" className="btn app-store-btn page-scroll">
                    Começar agora
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
                <div className="hero-animation-img">
                  {/* <img src="img/image-14.png" alt="img" className="img-fluid" /> */}
                  <img
                    src="img/header.png"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <ul className="list-inline counter-wrap">
                <li className="list-inline-item">
                  <div className="single-counter text-center">
                    <span>+</span>
                    <span>5250</span>
                    <h6>Consumidores atendidos</h6>
                  </div>
                </li>
                <li className="list-inline-item">
                  <div className="single-counter text-center">
                    <span>+</span>
                    <span>10300</span>
                    <h6>Pedidos com sucesso</h6>
                  </div>
                </li>
                <li className="list-inline-item">
                  <div className="single-counter text-center">
                    <span>+</span>
                    <span>250</span>
                    <h6>Mil reais transacionados</h6>
                  </div>
                </li>
                <li className="list-inline-item">
                  <div className="single-counter text-center">
                    <span>+</span>
                    <span>2450</span>
                    <h6>Produtos a venda</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({
  state,
}))(HeroSection);
