import React from "react";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import AgencyCoApp from "./reducers";
import Routes from "./routers";
import { hotjar } from 'react-hotjar';
import * as firebase from 'firebase/app'
import firebaseConfig from "./firebaseConfig";
import 'firebase/analytics';

// create store
const store = createStore(
  AgencyCoApp,
  composeWithDevTools()
  // applyMiddleware(...middleware),
  // other store enhancers if any
);

hotjar.initialize(1937878, 6);

firebase.initializeApp(firebaseConfig)
firebase.analytics();

function App() {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}

export default App;
