import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import HubspotForm from "react-hubspot-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { planMockedCallAction } from "./planMockedText";

const mockedPlans = {
  free: {
    portalId: "8249903",
    formId: "26dc240c-b4e5-41d5-9219-5e51230b8797",
    value: "Free",
  },
  essential: {
    portalId: "8249903",
    formId: "c104ef0a-c857-46f2-b4ba-48fccafce8df",
    value: "Essential",
  },
  premium: {
    portalId: "8249903",
    formId: "71ca2808-c466-4cb6-a432-a5999cada7ee",
    value: "Premium",
  },
};

const SignUp = () => {
  const [callActionText, setCallActionText] = useState("Crie sua Conta Ockpay");
  const [callActionBulletPoints, setCallActionBulletPoints] = useState([]);
  const { plano } = useParams();
  const history = useHistory();

  useEffect(() => {
    switch (plano) {
      case "free":
        setCallActionText(planMockedCallAction.free.text);
        setCallActionBulletPoints(planMockedCallAction.free.bulletPoints);
        break;

      case "essential":
        setCallActionText(planMockedCallAction.essencial.text);
        setCallActionBulletPoints(planMockedCallAction.essencial.bulletPoints);
        break;

      case "premium":
        setCallActionText(planMockedCallAction.premium.text);
        setCallActionBulletPoints(planMockedCallAction.premium.bulletPoints);
        break;

      default:
        setCallActionText(planMockedCallAction.free.text);
        setCallActionBulletPoints(planMockedCallAction.free.bulletPoints);
        break;
    }
  }, [plano]);

  const handleFormSubmit = async (data) => {
    const vatNumber = data.find(({ name }) => name === "cpf_cnpj").value;
    const name = data.find(({ name }) => name === "firstname").value;
    const fantasyName = data.find(({ name }) => name === "company").value;
    const plan = mockedPlans[plano].value;
    const email = data.find(({ name }) => name === "email").value;

    try {
      if (process.env.NODE_ENV === "production") {
        await axios({
          method: "post",
          url: "https://ms-restaurant.ockpay.com.br/establishment",
          data: {
            vatNumber: vatNumber,
            fantasyName: fantasyName,
            name: name,
            email: email,
            plan: plan,
            idBusinessType: null,
          },
        });
      }

      history.push("/confirmacao");

      return true;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <section
        className="hero-section ptb-100 background-img full-screen"
        style={{
          // backgroundImage: "url('img/hero-bg-1.jpg')",
          // backgroundImage: "url('https://i.imgur.com/dhEgDAd.jpg')",
          backgroundImage: "url('https://site.ockpay.com.br/img/bgplans.png')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between pt-5 pt-sm-5 pt-md-5 pt-lg-0">
            <div className="col-md-7 col-lg-6">
              <div className="hero-content-left text-white">
                <h1 className="text-white">{callActionText}</h1>
                <ul
                  style={{
                    listStyle: "inside",
                  }}
                >
                  {callActionBulletPoints.map((text, index) => (
                    <li
                      style={{
                        marginLeft: "5px",
                      }}
                      key={index}
                    >
                      {text}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5">
              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">
                  <HubspotForm
                    portalId={
                      plano && mockedPlans[plano] && mockedPlans[plano].portalId
                    }
                    formId={
                      plano && mockedPlans[plano] && mockedPlans[plano].formId
                    }
                    onSubmit={(data) => handleFormSubmit(data)}
                    onReady={(form) => console.log(form)}
                    loading={<div>Carregando...</div>}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-img-absolute">
          <img src="img/wave-shap.svg" alt="wave shape" className="img-fluid" />
        </div>
      </section>
    </React.Fragment>
  );
};

// class HeroSection extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       hero: {},
//       name: "",
//       email: "",
//       password: "",
//       cpfCnpj: '',
//       company: '',
//       isAgreed: '',
//       disableBtn: false,
//       btnText: "Criar conta",
//     };

//     this.handleSubmit = this.handleSubmit.bind(this);
//   }

//   changeBtnText = btnText => {
//     this.setState({ btnText });
//   };

//   handleFormValueChange(inputName, event) {
//     let stateValue = {};
//     stateValue[inputName] =
//       event.target.type === "checkbox"
//         ? event.target.checked
//         : event.target.value;
//     this.setState(stateValue);
//   }

//   handleSubmit(event) {
//     event.preventDefault();

//     // disable the button
//     this.setState({ disableBtn: true });

//     // // get action
//     const signUpAction = signUp(this.state);

//     // // Dispatch the contact from data
//     this.props.dispatch(signUpAction);

//     // // added delay to change button text to previous
//     setTimeout(
//       function() {
//         // enable the button
//         this.setState({ disableBtn: false });

//         // change to button name
//         this.changeBtnText("Sign up");

//         // get action again to update state
//         const signUpAction = signUp(this.state);

//         // Dispatch the contact from data
//         this.props.dispatch(signUpAction);

//         // clear form data
//         this.setState({
//           name: "",
//           email: "",
//           password: "",
//           isAgreed: false
//         });
//       }.bind(this),
//       3000
//     );
//   }

//   componentDidMount() {
//     /**
//      * Your ajax will goes here to get data then call setState
//      */
//   }

//   // render() {}
// }

export default connect((state) => ({
  state,
}))(SignUp);
