import React from "react";

class Feature extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prices: {
                packages: [],
            }
        };
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>
                <div id="features"
                     className={"feature-section ptb-100 " + (this.props.bgColor && this.props.bgColor === 'gray' ? 'gray-light-bg' : '')}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="section-heading text-center mb-5">
                  <span className="badge badge-primary badge-pill">
                    O que há de melhor
                  </span>
                                    <h2>
                                        Vender online e presencial  <br/>
                                        <span>ficou mais fácil com nossas melhores ideias.</span>
                                    </h2>
                                    <p>
                                        Um pacote incrível e sempre em expansão de funcionalidades para simplificar as
                                        vendas online e criar uma nova experiência em compras locais
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row row-grid align-items-center">
                            <div className="col-lg-4">
                                <div className="d-flex align-items-start mb-5">
                                    <div className="pr-4">
                                        <div className="icon icon-shape icon-color-1 rounded-circle">
                                            <span className="ti-face-smile"></span>
                                        </div>
                                    </div>
                                    <div className="icon-text">
                                        <h5>Seu delivery inteligente</h5>
                                        <p className="mb-0">
                                            Cálculo de frete e melhor rota automatizados com Google Maps
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start mb-5">
                                    <div className="pr-4">
                                        <div className="icon icon-shape icon-color-2 rounded-circle">
                                            <span className="ti-vector"></span>
                                        </div>
                                    </div>
                                    <div className="icon-text">
                                        <h5>Controle de pedidos</h5>
                                        <p className="mb-0">
                                            Um gestor completo que separa o que é pra entrega, o que é pra retirar e o
                                            que é consumo local na ponta dos dedos
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start">
                                    <div className="pr-4">
                                        <div className="icon icon-shape icon-color-3 rounded-circle">
                                            <span className="ti-headphone-alt"></span>
                                        </div>
                                    </div>
                                    <div className="icon-text">
                                        <h5>Contato com consumidores</h5>
                                        <p className="mb-0">
                                            Contato rápido e direto com o comprador automatizado com o WhatsApp
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="position-relative" style={{zIndex: 10}}>
                                    <img
                                        alt="placeholder"
                                        // src="img/image-10.png"
                                        src="img/features.png"
                                        className="img-center img-fluid"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex align-items-start mb-5">
                                    <div className="pr-4">
                                        <div className="icon icon-shape icon-color-4 rounded-circle">
                                            <span className="ti-layout-media-right"></span>
                                        </div>
                                    </div>
                                    <div className="icon-text">
                                        <h5>Gestão financeira</h5>
                                        <p className="mb-0">
                                            Acompanhamento em tempo real e histórico de pedidos com relatórios para
                                            gestão do negócio digital
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start mb-5">
                                    <div className="pr-4">
                                        <div className="icon icon-shape icon-color-5 rounded-circle">
                                            <span className="ti-layout-cta-right"></span>
                                        </div>
                                    </div>
                                    <div className="icon-text">
                                        <h5>Pagamentos integrados</h5>
                                        <p className="mb-0">
                                            Um clique e já está integrado nosso incrível e configurável sistema de
                                            pagamentos com cartão de crédito de 1 a 12x
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start">
                                    <div className="pr-4">
                                        <div className="icon icon-shape icon-color-6 rounded-circle">
                                            <span className="ti-palette"></span>
                                        </div>
                                    </div>
                                    <div className="icon-text">
                                        <h5>Em todo lugar</h5>
                                        <p className="mb-0">
                                            Vendas online para entrega e retirada no aplicativo Ockpay, nas redes
                                            sociais, no WhatsApp e local com o poder do QR Code
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Feature;
