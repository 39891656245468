import React from "react";
import {Link} from "react-router-dom";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>
                <footer className="footer-section">
                    <div
                        className={"footer-top background-img-2 " + (this.props.noSubscription ? 'py-5' : 'pt-150 pb-5')}
                        style={{
                            // backgroundImage: "url('img/footer-bg.png')",
                            // backgroundImage: "url('https://appco.themetags.com/img/footer-bg.png')",
                            // backgroundImage: "url('https://i.imgur.com/bJWHc7G.png')",
                            backgroundImage: "url('https://i.imgur.com/6NQKe0R.png')",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center top",
                            backgroundSize: "cover"
                        }}
                    >
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-lg-3 mb-3 mb-lg-0">
                                    <div className="footer-nav-wrap text-white">
                                        <img
                                            // src="img/logo-white-1x.png"
                                            src="https://i.imgur.com/Q4gntSt.png"
                                            alt="footer logo"
                                            width="120"
                                            className="img-fluid mb-3"
                                        />
                                        <p>
                                            Uma plataforma digital que une pesssoas e tecnologia 
                                            com experiências que reinventam a nossa relação com compras,
                                            do almoço ao presente, tudo em um só lugar, incrivelmente integrado 
                                            para unir empresas e consumidores.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                                    <div className="footer-nav-wrap text-white">
                                        <h5 className="mb-3 text-white">Nossos links</h5>
                                        <ul className="list-unstyled">
                                            <li className="mb-2">
                                                <a href="/#">Topo da página</a>
                                            </li>
                                            <li className="mb-2">
                                                <a href="/#contact">Contato</a>
                                            </li>
                                            <li className="mb-2">
                                                <a href="/#precos">Preços e planos</a>
                                            </li>
                                            <li className="mb-2">
                                                <a href="/politica-de-privacidade">Política de privacidade</a>
                                            </li>
                                            <li className="mb-2">
                                                {/* <a href="/termos-e-condicoes">Termos e condições</a> */}
                                                <Link to="/termos-e-condicoes">Termos e condições</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                                    <div className="footer-nav-wrap text-white">
                                        <h5 className="mb-3 text-white">Suporte</h5>
                                        <ul className="list-unstyled support-list">
                                            <li className="mb-2 d-flex align-items-center">
                                                <span className="ti-location-pin mr-2"></span>
                                                Americana/SP
                                            </li>
                                            <li className="mb-2 d-flex align-items-center">
                                                <span className="ti-mobile mr-2"></span>{" "}
                                                <a href="tel:+55283766284"> +55 (19) 98326-5919</a>
                                            </li>
                                            <li className="mb-2 d-flex align-items-center">
                                                <span className="ti-email mr-2"></span>
                                                <a href="mailto:mail@example.com"> contato@ockpay.com.br</a>
                                            </li>
                                            {/*<li className="mb-2 d-flex align-items-center">
                        <span className="ti-world mr-2"></span>
                        <a href="/#"> site.ockpay.com.br</a>
                      </li>*/}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="footer-nav-wrap text-white">
                                        <h5 className="mb-3 text-white">Redes Sociais</h5>
                                        <ul className="list-unstyled support-list">
                                            <li className="mb-2 d-flex align-items-center">
                                                <a href="https://www.facebook.com/ockpay.oficial"><span
                                                    className="ti-facebook mr-4"></span></a>
                                                <a href="https://www.instagram.com/ockpay"><span
                                                    className="ti-instagram mr-4"></span></a>
                                                <a href="https://www.linkedin.com/company/37421926"><span
                                                    className="ti-linkedin mr-4"></span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<div className="footer-bottom colored-footer-bottom pt-4 pb-4">
                        <div className="container">
                            <div className="row text-center justify-content-center">
                                <div className="col-md-6 col-lg-5">
                                    <p className="copyright-text pb-0 mb-0">

                                        <a href="/#"> Ockpay</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>*/}
                </footer>
            </React.Fragment>
        );
    }
}

export default Footer;
