import React from "react";
import { connect } from "react-redux";

class PromoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className={"promo-section ptb-100 " + (this.props.removeTopMargin ? '' : 'mt-5')}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-10">
                <div className="section-heading mb-5">
                  <span className="badge badge-primary badge-pill">
                    Para unir a todos
                  </span>
                  <h5 className="h5 mt-3 mb-6">
                    Criamos uma plataforma incrivelmente integrada para unir vendedores e consumidores
                  </h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-6 mb-lg-0">
                <div className="card single-promo-card single-promo-hover">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="ti-credit-card icon-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Integração</h5>
                      <p className="text-muted mb-0">
                        Das redes sociais ao aplicativo, tudo integrado em um só lugar.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="card single-promo-card single-promo-hover mb-lg-0">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="ti-control-record icon-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Experiência</h5>
                      <p className="text-muted mb-0">
                      Pedidos no local, no delivery ou pra retirar rápidos como mágica.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="card single-promo-card single-promo-hover mb-lg-0">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="ti-vector icon-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Variedade</h5>
                      <p className="text-muted mb-0">
                      Tem comida, produtos de beleza, livros, decoração e um monte de coisas.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="card single-promo-card single-promo-hover mb-lg-0">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="ti-receipt icon-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Simplicidade</h5>
                      <p className="text-muted mb-0">
                      Um fluxo de compras totalmente novo, pensado em simplificar a sua decisão.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(PromoSection);
