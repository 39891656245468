export const planMockedCallAction = {
  free: {
    text: "Plano Grátis Ockpay",
    bulletPoints: [
      "Sua loja online em 10 minutos.",
      "Link personalizado para vender pelas redes sociais e WhatsApp.",
      "Sistema gestor inteligente para gerenciar pedidos, clientes e produtos.",
      "Mais visibilidade para sua loja pelo aplicativo Ockpay.",
      "Taxa Zero (0%) nas vendas feitas por sua maquininha ou em dinheiro.",
      "Cartão de crédito online Ockpay, apenas 5% de comissão.",
      "Limite de 25 produtos cadastrados.",
      "Você que faz as entregas!",
    ]
  },
  essencial: {
    text: "Plano Essencial Ockpay",
    bulletPoints: [
      "Sua loja online em 10 minutos.",
      "Link personalizado para vender pelas redes sociais e WhatsApp.",
      "Sistema gestor inteligente para gerenciar pedidos, clientes e produtos.",
      "Mais visibilidade para sua loja pelo aplicativo Ockpay.",
      "Taxa Zero (0%) nas vendas feitas por sua maquininha ou em dinheiro.",
      "Cartão de crédito online Ockpay, apenas 5% de comissão.",
      "Pedidos no local: mais fácil para o cliente, mais barato para você!",
      "Sem limite de produtos cadastrados.",
      "Você que faz as entregas!",
    ]
  },
  premium: {
    text: "Plano Premium Ockpay",
    bulletPoints: [
      "Sua loja online em 10 minutos.",
      "Link personalizado para vender pelas redes sociais e WhatsApp.",
      "Sistema gestor inteligente para gerenciar pedidos, clientes e produtos.",
      "Mais visibilidade para sua loja pelo aplicativo Ockpay.",
      "Taxa Zero (0%) nas vendas feitas por sua maquininha ou em dinheiro.",
      "Cartão de crédito online Ockpay, apenas 5% de comissão.",
      "Pedidos no local: mais fácil para o cliente, mais barato para você!",
      "Sem limite de produtos cadastrados.",
      "Entrega com motoboys parceiros!",
    ]
  },
}