import React from "react";

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className="promo-section ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-9">
                <div className="section-heading mb-5">
                  <h2>Perguntas Frequentes</h2>
                  <p className="lead">
                    Veja abaixo as dúvidas mais solicitadas por nossos usuários.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div id="accordion-1" className="accordion accordion-faq">
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-1"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-1"
                      aria-expanded="false"
                      aria-controls="collapse-1-1"
                    >
                      <h6 className="mb-0">
                        <span className="ti-receipt mr-3"></span> Em quanto tempo recebo minhas vendas online?
                      </h6>
                    </div>
                    <div
                      id="collapse-1-1"
                      className="collapse"
                      aria-labelledby="heading-1-1"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                        Vendas online feitas com o cartão de crédito estarão disponíveis 
                        após 31 dias a contar da data de pagamento e serão repassadas todas 
                        as quartas-feiras. Vendas realizadas via PIX estarão disponíveis no 
                        momento do pagamento diretamente na conta bancária cadastrada na Ockpay.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-2"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-2"
                      aria-expanded="false"
                      aria-controls="collapse-1-2"
                    >
                      <h6 className="mb-0">
                        <span className="ti-gallery mr-3"></span> Como gerencio meus pedidos e vendas?
                      </h6>
                    </div>
                    <div
                      id="collapse-1-2"
                      className="collapse"
                      aria-labelledby="heading-1-2"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                        Em seu gestor (gestor.ockpay.com.br), você poderá visualizar seus 
                        pedidos na aba "Pedidos" e em "Vendas". Você poderá baixar relatórios 
                        para análise ou mesmo fazer tudo pelo próprio gestor.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-3"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-3"
                      aria-expanded="false"
                      aria-controls="collapse-1-3"
                    >
                      <h6 className="mb-0">
                        <span className="ti-wallet mr-3"></span> Como cancelar um pedido?
                      </h6>
                    </div>
                    <div
                      id="collapse-1-3"
                      className="collapse"
                      aria-labelledby="heading-1-3"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                        Em seu gestor (gestor.ockpay.com.br), vá até o pedido que 
                        deseja cancelar e selecione "Cancelar e estornar pedido".
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div id="accordion-2" className="accordion accordion-faq">
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-1"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-1"
                      aria-expanded="false"
                      aria-controls="collapse-2-1"
                    >
                      <h6 className="mb-0">
                        <span className="ti-receipt mr-3"></span> Como configuro minhas entregas?
                      </h6>
                    </div>
                    <div
                      id="collapse-2-1"
                      className="collapse"
                      aria-labelledby="heading-2-1"
                      data-parent="#accordion-2"
                    >
                      <div className="card-body">
                        <p>
                        Em seu gestor (gestor.ockpay.com.br), vá até "Entrega" e
                         configure conforme o seu modelo de atendimento. 
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-2"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-2"
                      aria-expanded="false"
                      aria-controls="collapse-2-2"
                    >
                      <h6 className="mb-0">
                        <span className="ti-lock mr-3"></span> Como altero minha senha do gestor?
                      </h6>
                    </div>
                    <div
                      id="collapse-2-2"
                      className="collapse"
                      aria-labelledby="heading-2-2"
                      data-parent="#accordion-2"
                    >
                      <div className="card-body">
                        <p>
                        Em seu gestor (gestor.ockpay.com.br), vá em "Geral" e selecione a opção "Trocar Senha".
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-3"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-3"
                      aria-expanded="false"
                      aria-controls="collapse-2-3"
                    >
                      <h6 className="mb-0">
                        <span className="ti-widget mr-3"></span> Em quais dispositivos posso abrir meu gestor?
                      </h6>
                    </div>
                    <div
                      id="collapse-2-3"
                      className="collapse"
                      aria-labelledby="heading-2-3"
                      data-parent="#accordion-2"
                    >
                      <div className="card-body">
                        <p>
                          Você pode abrir seus gestor (gestor.ockpay.com.br) pelo celular, computador ou tablet.
                          É só digitar o endereço no navegador, fazer o login e pronto!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="gray-light-bg ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading mb-5 text-center">
                  <h2>Mais Dúvidas</h2>
                  <p className="lead">
                    As dúvidas de outros podem ser as suas também! 
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="single-faq">
                  <h5>Quais bancos são aceitos?</h5>
                  <p>
                  Banco do Brasil, Banco Original, Banestes, Banrisul, Bradesco, 
                  BRB, BS2, C6, Caixa Econômica, Inter, Itaú, Mercantil do Brasil, Modal,
                   Neon, Nubank, PagSeguro, Safra, Santander, Sicoob (Bancoob), Sicredi, Unicred e Via Credi.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-faq">
                  <h5>Quais as formas de pagamento disponíveis?</h5>
                  <p>
                  Cartão de crédito (MasterCard, Visa, Diners, American Express, 
                  AMEX e Elo), PIX e Dinheiro (para pagamentos na entrega ou na retirada).{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-faq">
                  <h5>Quais os modelos de entrega disponíveis?</h5>
                  <p>
                  Você poderá configurar seu sistema de entrega próprio em todos os planos 
                  (Grátis, Essencial e Premium). No plano premium é possível entregar para o Brasil 
                  todo através das transportadoras disponíveis em sua região (em parceria com a Melhor Envio)
                   e via motoboy (ver lista de cidades atendidas).
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-faq">
                  <h5>Como cancelar minha assinatura Ockpay?</h5>
                  <p>
                    Basta entrar no chat em seu gestor e solicitar o cancelamento para nossa equipe.{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-faq">
                  <h5>Existe algum limite de produtos para cadastrar?</h5>
                  <p>
                    Somente o plano gratuito possui o limite de 10 produtos para cadastro. 
                    Os planos Essencial e Premium não possuem limites para cadastro de itens.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-faq">
                  <h5>Minhas vendas são seguras com cartão de crédito online?</h5>
                  <p>
                  A Ockpay segue 100% dos requisitos PCI, indo um pouco mais além ao isolar 
                  os sub-sistemas de cobrança da plataforma, retirando-os da Internet e 
                  restringindo o acesso físico através de chaves físicas duplas + chave de acesso rsa.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Faq;
