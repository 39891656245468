import React from "react";

class AboutUsDelivery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="about" className="about-us ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <span className="badge badge-primary badge-pill">
                  Delivery e Retirada
                </span>
                <h2 className="mt-4">Entregue seus produtos no mesmo dia e para todo o Brasil</h2>
                <p className="mb-4 lh-190">
                  A Ockpay é muito mais que uma plataforma de compras, é a união entre a comodidade de comprar em sua cidade e receber/retirar no mesmo dia ou comprar de qualquer lugar do Brasil.
                </p>
                <ul className="list-unstyled">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Frete inteligente integrado com Google Maps 
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Entregas locais com cálculo de frete automático e configurável
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Envio para todo o Brasil com mais de 250 transportadoras
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Chat automático entre estabelecimento/consumidores com WhatsApp 
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">
                          Contato rápido e direto com entregadores via WhatsApp
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-5">
                <div className="about-content-right">
                  <img
                    src="img/delivery-app.svg"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUsDelivery;
